import Api from "@/apis/Api.js";

export default {
    getReportsData() {
        return Api.get('/reports');
    },

    getLatestTimestamp() {
        return Api.get('/latest-timestamp');
    }
};
