<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Acasa</h1>
      </v-row>
      <v-row>
        <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Numar total de utilizatori creati</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >{{ totalUsers }}</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="2">
                  <div class="card-light-grey">Total super admini</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ totalSuperAdmins }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div class="card-light-grey">Total transporatori</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ this.totalCarries }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div class="text-right card-light-grey">Total admini logistica</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{ this.totalAdmins }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Numar total de rute create</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >{{ totalShippingRoutes }}</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Numar total de licitatii create</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >{{ totalRouteBid }}</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Numar total de date importate CSV</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >{{ totalImportsData }}</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>Utilizatori existenti</p>
              <v-spacer></v-spacer>
              <admin-modal-create :superAdminAccess="superAdminAccess" />
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">Id</th>
                      <th class="text-left">Nume</th>
                      <th class="text-left">Prenume</th>
                      <th class="text-left">Numar de telefon</th>
                      <th class="text-left">Nume companie</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Rol</th>
                      <th 
                          class="text-left" 
                          v-if="superAdminAccess">
                          Actiuni
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="admin in admins" :key="admin.id">
                      <td class="pa-6">{{ admin.id }}</td>
                      <td>{{ admin.first_name }}</td>
                      <td>{{ admin.last_name }}</td>
                      <td>{{ admin.phone_number }}</td>
                      <td>{{ admin.company_name }}</td>
                      <td>{{ admin.email }}</td>
                      <td>{{ admin.roles }}</td>
                      <td>
                        <admin-modal-edit 
                          :admin="admin"
                          :superAdminAccess="superAdminAccess"
                          v-if="admin.roles != 'SUPER_ADMIN'"
                        />
                        <div class="text-center">
                          <v-icon 
                            style="font-size: 28px" color="error"
                            @click="deleteAdmin(admin.id)"
                            v-if="superAdminAccess && admin.roles != 'SUPER_ADMIN'"
                            >mdi-account-minus
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import mock from "./mock";
import AdminModalCreate from "@/components/Modals/AdminModalCreate";
import AdminModalEdit from "@/components/Modals/AdminModalEdit";
import Admin from "@/apis/Admin.js";
import Reports from "@/apis/Reports.js";

export default {
  name: "Dashboard",
  components: {
    AdminModalCreate,
    AdminModalEdit
  },
  data() {
    return {
      admins: [],
      superAdminAccess: false,
      mock,
      apexLoading: false,
      mainApexAreaSelect: "Daily",
      totalUsers: '',
      totalSuperAdmins: '',
      totalCarries: '',
      totalShippingRoutes: '',
      totalRouteBid: '',
      totalImportsData: '',
      totalAdmins: ''
    };
  },
  mounted() {
    this.superAdminAccess = this.isSuperAdmin();
    this.$nextTick(() => {
      this.getAdmins();
      this.getReportsData();
      if (this.isCarry() === true ) {
        this.$router.push({ name: 'ShippingRoutes' });
      }
    });
  },
  methods: {
    getReportsData() {
      Reports.getReportsData()
        .then((response) => {
          this.totalUsers = response.data.data.totalUsers;
          this.totalSuperAdmins = response.data.data.totalSuperAdmins;
          this.totalCarries = response.data.data.totalCarries;
          this.totalAdmins  = response.data.data.totalAdmins;
          this.totalShippingRoutes = response.data.data.totalShippingRoutes;
          this.totalRouteBid = response.data.data.totalRouteBid;
          this.totalImportsData = response.data.data.totalDataImport;
        })
    },

    getAdmins() {
      Admin.getAdmins()
        .then((response) => {
          this.admins = response.data.data;
        })
    },

    deleteAdmin(id) {
      Admin.deleteAdmin(id)
        .then(() => {
          window.showSuccess('Succes!', 'Adminul a fost sters cu succes')
          this.getAdmins();
        })
    },
  }
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
