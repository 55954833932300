<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon 
          style="font-size: 28px" color="success"
          dark
          v-bind="attrs"
          v-if="carryAccess"
          v-on="on">mdi-cash-multiple</v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Plaseaza licitatie
        </v-card-title>
        <v-form>
            <v-col>
                <v-text-field
                    label="Oferta licitatie"
                    name="bid_value"
                    v-validate="'required'"
                    data-vv-as="valoarea licitatiei"
                    v-model="data.bid_value"
                    required
                ></v-text-field>

                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('bid_value') }}
                        </div>
                    </div>
                </div>

                <v-textarea
                    label="Detalii in plus"
                    name="comment"
                    data-vv-as="detalii in plus"
                    v-model="data.comment"
                    required
                ></v-textarea>

                <v-select
                  :items="types"
                  v-model="data.type"
                  @onChange="data.type"
                ></v-select>
                <v-select
                  :items="currencies"
                  v-model="data.currency"
                  @onChange="data.currency"
                ></v-select>

            </v-col>
        </v-form>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="placeBid"
          >
            Plaseaza licitatia
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import User from '@/apis/User.js';
  export default {
    props: {
        route: Object,
        carryAccess: Boolean
    },
    data () {
      return {
        dialog: false,
        currency: 'EURO',
        types: ['km', 'total'],
        currencies: ['EUR', 'RON'],
        data: {
            bid_value: '',
            type: 'km',
            route_id: this.route.id,
            route_name: this.route.route_name,
            currency: 'EUR'
        }
      }
    },

    methods: {
        placeBid() {
            User.placeBid(this.data)
                .then(() => {
                    this.dialog = false;
                    window.showSuccess('Succes!', 'Licitatia a fost plasata cu succes!');
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                });
        }
    }
  }
</script>