import Api from "@/apis/Api.js";

export default {
    getAddresses() {
        return Api.get('/addresses');
    },

    getShippingData() {
        return Api.get('/shipping-routes');
    },
};
