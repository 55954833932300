<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/logo.svg" contain></v-img>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-tabs grow>
                  <v-tab :href="`#tab-reset-password`">
                    Logare
                  </v-tab>
                  <v-tab-item :value="'tab-reset-password'" >
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col>
                            <p class="login-slogan display-2 text-center font-weight-medium my-10">Resetare parola</p>
                          </v-col>
                          <v-form>
                            <v-col>
                            <v-col>
                            </v-col>
                            <v-text-field
                                  name="password"
                                  v-model="data.password"
                                  type="password"
                                  label="Parola noua"
                                  ref="password"
                                  v-validate="'required|min:8'"
                                  data-vv-as="parola"
                                  required
                            ></v-text-field>
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        {{ errors.first('password') }}
                                    </div>
                                </div>
                            </div>
                            <v-text-field
                                  name="password_confirmation"
                                  v-model="data.password_confirmation"
                                  type="password"
                                  label="Confirma parola noua"
                                  v-validate="'required|min:8|confirmed:password'"
                                  data-vv-as="confirma parola"
                                  required
                              ></v-text-field>
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        {{ errors.first('password_confirmation') }}
                                    </div>
                                </div>
                            </div>
                            </v-col>
                            <v-col class="d-flex justify-space-between">
                              <v-btn
                                  class="text-capitalize"
                                  large
                                  color="primary"
                                  @click="resetPassword"
                              >
                                Resetare parola</v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© {{ date.getFullYear() }} <a href="/" class="text-decoration-none"> Proinvest Group SRL • Str. Gradinitei nr. 1, RO - 705200 Pascani (Iasi)</a></div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import User from "@/apis/User.js";
  export default {
    name: 'Login',
    data() {
      return {
        data: {
          password: '',
          password_confirmation: '',
          token: window.location.href.split('/?token=').pop()
        },
        date: new Date()
      }
    },
    methods: {
      resetPassword() {
        User.resetPassword(this.data)
          .then(() => {
            window.showSuccess('Succes', 'Parola ta a fost resetata cu succes!')
            this.$router.push({ name: "Login" })
          })
      }
    },
    created() {
      if (window.localStorage.getItem('auth') === 'true') {
        this.$router.push('/dashboard');
      }
    }
  }

</script>

<style src="./Login.scss" lang="scss"/>
