import Api from "@/apis/Api.js";

export default {

    getAdmins() {
        return Api.get('/admins');
    },

    createAdmin(data) {
        return Api.post('/create-admin', data);
    },

    editAdmin(id, data) {
        return Api.put(`/edit-admin/${id}`, data);
    },

    deleteAdmin(id) {
        return Api.delete(`/delete-admin/${id}`)
    }, 

    adminData() {
        return Api.get("/admin-data");
    },

    importProductsData() {
        return Api.get("/import-products-data");
    },

    syncProducts() {
        return Api.post("/sync-products");
    },

    importProductsManually(data) {
        return Api.post('/import-products-manually', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },

    editShippingRoute(id, data) {
        return Api.put(`/edit-shipping-route/${id}`, data);
    },

    deleteShippingRoute(id) {
        return Api.delete(`delete-shipping-route/${id}`);
    },

    getBidsData() {
        return Api.get('/bids-data');
    },

    acceptBid(id) {
        return Api.put(`/accept-bid/${id}`);
    },

    rejectBid(id) {
        return Api.put(`/reject-bid/${id}`);
    },

    createRoute(data) {
        return Api.post('/create-shipping-route', data);
    },

    acceptRoute(id) {
        return Api.put(`/accept-route/${id}`);
    },

    rejectRoute(id) {
        return Api.put(`/reject-route/${id}`);
    }
};
