<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon 
          style="font-size: 28px" color="success"
          dark
          v-bind="attrs"
          v-if="adminAccess || superAdminAccess"
          v-on="on">mdi-tooltip-edit</v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Editeaza ruta
        </v-card-title>
        <v-form>
            <v-col>
                <v-text-field
                    label="Nume"
                    name="route_name"
                    v-validate="'required'"
                    data-vv-as="nume"
                    v-model="data.route_name"
                    required
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('route_name') }}
                        </div>
                    </div>
                </div>
                <v-text-field
                    label="Numar total de km"
                    name="total_km"
                    v-validate="'numeric'"
                    data-vv-as="numar total km"
                    v-model="data.total_km"
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('total_km') }}
                        </div>
                    </div>
                </div>
              <label 
                for="uploaded_on" 
              >
                De incarcat la data de: 
              </label>
              <input 
                type="date" 
                v-model="data.uploaded_on" 
                id="uploaded_on" 
                name="uploaded_on"
              >
              <br>
              <label 
                for="uploaded_on" 
              >
                De descarcat la data de: 
              </label>
              <input 
                type="date" 
                v-model="data.downloaded_on" 
                id="downloaded_on" 
                name="downloaded_on"
              >
            </v-col>
        </v-form>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="editShippingRoute(route.id)"
          >
            Editeaza ruta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Admin from '@/apis/Admin.js';
  export default {
    props: {
        route: Object,
        adminAccess: Boolean,
        superAdminAccess: Boolean
    },
    data () {
      return {
        dialog: false,
        data: {
          route_name: this.route.route_name.split(','),
          total_km: this.route.total_km,
          uploaded_on: this.route.uploaded_on,
          downloaded_on: this.route.downloaded_on
        }
      }
    },

    methods: {
        editShippingRoute(id) {
            Admin.editShippingRoute(id, this.data)
                .then(() => {
                    this.dialog = false;
                    window.showSuccess('Succes!', 'Ruta a fost editata cu succces!');
                    setTimeout(() => {
                      window.location.reload();
                    }, 1500);
                });
        }
    }
  }
</script>