import swal from 'sweetalert2';

const Toast = swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', swal.stopTimer);
        toast.addEventListener('mouseleave', swal.resumeTimer);
    },
});

const Alert = swal.mixin({
    confirmButtonColor: '#3c64b1',
    showConfirmButton: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
});

window.showSuccess = (title, message = null) => {
    Alert.fire({
        icon: 'success',
        title,
        html: message,
    });
};

window.showError = (title, message = null) => {
    Alert.fire({
        icon: 'warning',
        title,
        html: message,
    });
};


window.showToastSuccess = (title) => {
    Toast.fire({
        icon: 'success',
        title,
    });
};
