<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Licitatii</h1>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left">Id</th>
                      <th class="text-left">Nume</th>
                      <th class="text-left">Prenume</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Numar de telefon</th>
                      <th class="text-left">Valoarea licitatiei</th>
                      <th class="text-left">Tipul valutei</th>
                      <th class="text-left">Tipul valorii</th>
                      <th class="text-left">Ruta</th>
                      <th class="text-left">Status</th>

                      <th class="text-left">Licitat la ora</th>
                      <th class="text-left">Detalii in plus</th>
                      <th class="text-left">Actiuni</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="bid_data in bids_data" :key="bid_data.id">
                    <td class="pa-6">{{ bid_data.id }}</td>
                    <td>{{ bid_data.first_name }}</td>
                    <td>{{ bid_data.last_name }}</td>
                    <td>{{ bid_data.email }}</td>
                    <td>{{ bid_data.phone_number }}</td>
                    <td>{{ bid_data.bid_value }}</td>
                    <td>{{ bid_data.currency}} </td>
                    <td>{{ bid_data.type }}</td>
                    
                    <td>{{ bid_data.route_name }}</td>
                    <td>{{ bid_data.status }} </td>
                    <td>{{ moment(bid_data.created_at).format("DD-MM-YYYY h:mm:ss a") }} </td>
                    <td>{{ bid_data.comment }} </td>
                    <td>
                        <div class="text-center" v-if="bid_data.status != 'Respins' && bid_data.status != 'Acceptat'">
                          <v-icon 
                            style="font-size: 28px" color="success"
                            v-if="superAdminAccess"
                            @click="acceptBid(bid_data.id)"
                            >mdi-check
                          </v-icon>
                        </div>
                        <div class="text-center" v-if="bid_data.status != 'Respins' && bid_data.status != 'Acceptat'">
                          <v-icon 
                            style="font-size: 28px" color="error"
                            v-if="superAdminAccess"
                            @click="rejectBid(bid_data.id)"
                            >mdi-minus-box
                          </v-icon>
                        </div>
                    </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Admin from '@/apis/Admin';
import moment from 'moment';
export default {
  name: "Bids",
  data() {
    return {
      superAdminAccess: false,
      adminAccess: false,
      carryAccess: false,
      bids_data: [],
      moment: moment
    }; 
  },
  mounted() {
    this.superAdminAccess = this.isSuperAdmin();
    this.adminAccess = this.isAdmin();
    this.carryAccess = this.isCarry();
    this.$nextTick(() => {
    this.getBidsData();
    if (this.isCarry() === true ) {
        this.$router.push({ name: 'ShippingRoutes' });
      }
    })
  },
  methods: {
    getBidsData() {
        Admin.getBidsData()
            .then((response) => {
                this.bids_data = response.data.data
            });
    },

    acceptBid(id) {
        Admin.acceptBid(id)
            .then(() => {
                window.showSuccess('Succes!', 'Licitatia a fost acceptata!');
                this.getBidsData();
            });
    },

    rejectBid(id) {
        Admin.rejectBid(id)
            .then(() => {
                window.showSuccess('Succes!', 'Licitatia a fost respinsa!');
                this.getBidsData();
            });
    }
  }
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
