import axios from "axios";

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

Api.interceptors.request.use((config) => {
  config.headers.Authorization = 'Bearer' + ' ' + window.localStorage.getItem('token');

  return config;
}, (error) =>{
  return Promise.reject(error);
});

Api.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2x
      if (error.response.status === 422) {
        if (typeof error.response.data.errors === "object") {
            const errors = Object.values(error.response.data.errors);
            window.showError('Eroare', errors.join('<br>'));
        } else {
            window.showError('Eroare', error.response.data.messages);
        }
      } else {
          // window.showError(error);
      }
  } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      window.showError('', error);
  } else {
      // Something happened in setting up the request that triggered an Error
      window.showError('', error);
  }

  // Do something with response error
  return Promise.reject(error);
});


Api.defaults.withCredentials = false;

export default Api;
