<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Rute transportatori</h1>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left">Id</th>
                      <th class="text-left">Ruta</th>
                      <th class="text-left">Total km</th>
                      <th class="text-left">De incarcat la data de</th>
                      <th class="text-left">De descarcat la data de</th>
                      <th class="text-left">Ruta creata de catre</th>
                      <th class="text-left">Actiuni</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-for="shipping_route_data in shipping_routes_data" 
                      :key="shipping_route_data.id"
                      :class="[shipping_route_data.accepted === 1 ? 'acceptedRoute' : '']"
                      @click="openDialog"
                    >
                      <td class="pa-6">{{ shipping_route_data.id }}</td>
                      <td>{{ shipping_route_data.route_name }}</td>
                      <td>{{ shipping_route_data.total_km }}</td>
                      <td>{{ shipping_route_data.uploaded_on }}</td>
                      <td>{{ shipping_route_data.downloaded_on }}</td>
                      <td>{{ shipping_route_data.created_by }}</td>
                      <td>
                          <route-data-model
                              :route="shipping_route_data"
                              v-if="superAdminAccess || adminAccess">
                          </route-data-model>
                          <shipping-routes-edit
                            :route="shipping_route_data"
                            :superAdminAccess="superAdminAccess"
                            :adminAccess="adminAccess"
                             v-if="superAdminAccess || adminAccess"
                          />
                          <div class="text-center">
                            <v-icon
                              style="font-size: 28px" color="error"
                              v-if="superAdminAccess || adminAccess"
                              @click="deleteShippingRoute(shipping_route_data.id)"
                              >mdi-delete
                            </v-icon>
                          </div>
                          <shipping-bid
                              :carryAccess="carryAccess"
                              :route="shipping_route_data"
                          />
                          <div class="text-center" v-if="shipping_route_data.accepted != 1 && shipping_route_data.accepted != 0">
                            <v-icon
                              style="font-size: 28px" color="success"
                              v-if="superAdminAccess || adminAccess"
                              @click="acceptRoute(shipping_route_data.id)"
                              >mdi-check
                            </v-icon>
                          </div>

                          <div class="text-center" v-if="shipping_route_data.accepted != 1 && shipping_route_data.accepted != 0">
                            <v-icon
                              style="font-size: 28px" color="error"
                              v-if="superAdminAccess || adminAccess"
                              @click="rejectRoute(shipping_route_data.id)"
                              >mdi-minus-box
                            </v-icon>
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import User from '@/apis/User';
import Admin from '@/apis/Admin';
import ShippingRoutesEdit from "@/components/Modals/ShippingRoutesEdit";
import ShippingBid from '@/components/Modals/ShippingBid';
import RouteDataModel from "@/components/Modals/RouteDataModel";
export default {
  name: "ShippingRoutes",
  components: {
      ShippingRoutesEdit,
      ShippingBid,
      RouteDataModel
  },
  data() {
    return {
      superAdminAccess: false,
      adminAccess: false,
      carryAccess: false,
      shipping_routes_data: [],
      dialog: false,
    }; 
  },
  mounted() {
    this.superAdminAccess = this.isSuperAdmin();
    this.adminAccess = this.isAdmin();
    this.carryAccess = this.isCarry();
    this.$nextTick(() => {
      this.getShippingRoutesData();
    })
  },
  methods: {
    getShippingRoutesData() {
        User.getShippingData()
            .then((response) => {
                this.shipping_routes_data = response.data.data
        });
    },

    deleteShippingRoute(id) {
        Admin.deleteShippingRoute(id)
            .then(() => {
                window.showSuccess('Succes', 'Ruta a fost stearsa cu succes!');
                this.getShippingRoutesData();
            });
    },

    acceptRoute(id) {
      Admin.acceptRoute(id)
        .then(() => {
          window.showSuccess('Succes', 'Ruta a fost acceptata cu succes');
          this.getShippingRoutesData();
        });
    },

    rejectRoute(id) {
      Admin.rejectRoute(id)
        .then(() => {
          window.showSuccess('Succes', 'Ruta a respinsa cu succes');
          this.getShippingRoutesData();
        });
    },
  }
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
