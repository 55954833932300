import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import ResetPassword from "@/pages/Login/ResetPassword";
import Import from "@/pages/Dashboard/Import";
import ShippingRoutes from "@/pages/Dashboard/ShippingRoutes";
import Google from "@/pages/Maps/Google";
import Bids from "@/pages/Dashboard/Bids";

Vue.use(Router);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guestOnly: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { guestOnly: true }
  },
  {
    path: '/orders-map',
    name: 'Google',
    component: Google,
    meta: { authOnly: true }
  },
  {
  path: '/',
  redirect: 'login',
  name: 'Layout',
  component: Layout,
  meta: { authOnly: true },
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: 'import',
      name: 'Import',
      component: Import,
    },
    {
      path: 'shipping-routes',
      name: 'ShippingRoutes',
      component: ShippingRoutes,
    },
    {
      path: 'bids',
      name: 'Bids',
      component: Bids,
    }
  ],
},
  {
    path: '*',
    name: 'Error',
    component: Error
  }
];


const router = new Router({
  mode: "history",
  routes
});

function isLoggedIn() {
  return localStorage.getItem("auth");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;