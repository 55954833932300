import Api from "@/apis/Api.js";

export default {
  login(data) {
    return Api.post("/login", data);
  },

  logout() {
    return Api.post("/logout", window.localStorage.getItem('token'));
  },

  sendForgotPasswordEmail(data) {
    return Api.post("/forgotten-password", data);
  },

  resetPassword(data) {
    return Api.put('/reset-password', data);
  },

  getShippingData() {
    return Api.get('/shipping-routes');
  },

  placeBid(data) {
    return Api.post('/place-bid', data);
  }
};
