import { MapElementFactory } from "vue2-google-maps";
export default MapElementFactory({
  name: "directionsRenderer",
  ctr() {
    return window.google.maps.DirectionsRenderer;
  },
  events: [],
  mappedProps: {},
  props: {
    origin: { type: [String] },
    destination: { type: [String] },
    travelMode: { type: String },
    waypoints: { type: [Object, Array] },
  },
  afterCreate(directionsRenderer) {
    let directionsService = new window.google.maps.DirectionsService({suppressMarkers: true});
    this.$watch(
      () => [this.origin, this.destination, this.travelMode, this.waypoints],
      () => {
        let { origin, destination, travelMode, waypoints} = this;
        if (!origin || !destination || !travelMode) return;
        directionsService.route(
          {
            origin,
            destination,
            travelMode,
            waypoints
          },
          (response, status) => {
            if (status !== "OK") return;
            // eslint-disable-next-line no-debugger
            //debugger
            directionsRenderer.setDirections(response);
            directionsRenderer.setOptions({  
              suppressMarkers: true
            });
            const route = response.routes[0];
            let totalKm = '';
            for (let i = 0; i < route.legs.length; i++) {
              totalKm = parseInt(route.legs[i].distance.text.replace('km',''), 10) + parseInt(route.legs[i].distance.text.replace('km',''), 10);
            }
            this.$emit('input', totalKm);
          }
        );
      }
    );
  },
});