<template>
  <v-app-bar
    class="main-header"
    height="64"
    fixed
    color='primary'
    dark>
    <v-toolbar-title >Proinvest</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-menu
      min-width="180"
      offset-y
      bottom
      left
      nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-0"
            icon
            v-bind="attrs"
            v-on="on">
            <v-icon
              style="font-size: 28px"
              :color="config.light.iconColor">mdi-account</v-icon>
            </v-btn>
        </template>
        <v-list >
          <div class="text-h5 grey--text text--darken-3 px-4 pt-4">
              {{ this.adminDetails.first_name + ' ' + this.adminDetails.last_name }}
          </div>
          
          <div class="d-flex justify-center my-3">
            <v-btn
              width="80%"
              large
              outlined
              color="primary"
              class="text-capitalize"
              @click="logout">Delogare
            </v-btn>
          </div>
        </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex'
import config from '@/config';
import User from '@/apis/User.js';
import Admin from '@/apis/Admin';
  export default {
    name: 'Header',
    data: () => ({
      config,
      adminDetails: {}
    }),
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE :{
        get() {
          return this.drawer
        },
      },
    },
    methods: {
      logout() {
        User.logout().then(() => {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("IS_SUPER_ADMIN");
           window.localStorage.removeItem("ADMIN");
        });
        window.localStorage.removeItem("auth")
        this.$router.push('/login');
      },

      getAdminDetails() {
        Admin.adminData()
          .then((response) => {
            this.adminDetails = response.data.data
          })
      }
    },

    created() {
      this.getAdminDetails();
    }
  };
</script>

<style src="./Header.scss" lang="scss"></style>
