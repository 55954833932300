<template>
  <div class="mainContainer">
    <div class="mapContainer">
      <GmapMap 
        id="maps" 
        ref="maps"
        disableDefaultUi: false
        :center="{lat: 45.9432, lng: 24.9668}"
        :zoom="7"
        style="width: 100%; height: inherit"
      >
      <GmapMarker
          v-for="address in addresses" :key="address.id"
          :position="{lat: address.lat, lng: address.log}"
          :icon="{ url: colors[address.department]}" 
          @click="addDetailsToRoute(address)"
        />
      />
      <DirectionsRenderer
        travelMode="DRIVING"
        :origin="startLocation"
        :destination="endLocation"
        :waypoints="waypoints"
        v-model="data.total_km"
      />
      <GmapInfoWindow
        :options="{
          maxWidth: 300,
          pixelOffset: { width: 0, height: -35 }
        }"
        :position="infoWindow.position"
        :opened="infoWindow.open"
        @closeclick="infoWindow.open=false">
        <div v-html="infoWindow.template"></div>
      </GmapInfoWindow>
        <GmapPolyline 
            v-if="path.length > 0" 
            :path.sync="path"
            :options="{ strokeColor:'#86caf7'}"
            ref="polyline">
        </GmapPolyline>
      </GmapMap>
    </div>

    <div class="itemsContainer">
      <table>
        <tr>
          <th>Plecare (1)</th>
          <th><GmapAutocomplete @place_changed="setLocation" /></th>
          <th style="width: 50%;"> 
            <v-btn
                style="background-color: #4287f5 !important;"
                @click="addMarker(0)"
              >
              Adauga ruta
            </v-btn></th>
        </tr>
        <tr>
          <th>Via (2)</th>
          <th><GmapAutocomplete @place_changed="setVia" id="via" /></th>
          <th style="width: 50%;">
            <v-btn
                style="background-color: #4287f5 !important;"
                @click="addMarker(2)"
              >
              Adauga ruta
            </v-btn>
          </th>
        </tr>
        <tr>
          <th>Oprire (3)</th>
          <th><GmapAutocomplete @place_changed="setDestination" /></th>
          <th style="width: 50%;"> 
            <v-btn
                style="background-color: #4287f5 !important;"
                @click="addMarker(1)"
              >
              Adauga ruta
            </v-btn></th>
        </tr>
      </table>

      <div class="routesContainer">
          <v-card
            class="mx-auto routesCard text-xs-center"
            align="center"
            justify="center"
          >
            <v-list>
              <v-list-item-group v-model="model">
                <v-list-item
                  v-for="(address, i) in data.route_name"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title 
                      v-text="address" @click="onAddressDelete(address)" 
                      style="font-size:23px !important;"
                      font-weight="bold">
                    </v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item-title 
                    v-if="data.maximum_weight > 0"
                    style="font-size:23px !important;"
                    font-weight="bold">
                  Greutate totala: {{ data.maximum_weight }}
              </v-list-item-title>
              <label 
                for="uploaded_on" 
                v-if="data.route_name.length >= 2"
              >
                De incarcat la data de: 
              </label>
              <input 
                type="date" 
                v-model="data.uploaded_on" 
                id="uploaded_on" 
                name="uploaded_on"
                v-if="data.route_name.length >= 2"
              >
              <br>
              <label 
                for="uploaded_on" 
                v-if="data.route_name.length >= 2"
              >
                De descarcat la data de: 
              </label>
              <input 
                type="date" 
                v-model="data.downloaded_on" 
                id="downloaded_on" 
                name="downloaded_on"
                v-if="data.route_name.length >= 2"
              >
              <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    filled
                    name="input-7-4"
                    label="Detalii ruta"
                    v-if="data.route_name.length >= 2"
                    v-model="data.comment"
                  ></v-textarea>
              </v-col>
              </v-list-item-group>
              <v-btn
                color="success"
                dark
                @click="createRoute"
                v-if="data.route_name.length >= 2"
              >
              Creeaza ruta
            </v-btn>
            </v-list>
          </v-card>
        </div>
        <div class="routesContainer">
          <v-card
            class="mx-auto routesCard"
            align="center"
            justify="center"
          >
            <v-list v-if="routes.length > 0">
             <v-list-item-title 
                style="font-size:23px !important;"
                font-weight="bold"
              >
              Rute existente
              </v-list-item-title>
              <v-btn
                color="success"
                dark
                @click="redirectToBids"
              >
              Catre pagina de licitatii
            </v-btn>
              <v-list-item-group v-model="model">
                <v-list-item
                  v-for="(item, i) in routes"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-truck</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title 
                      style=
                      "font-size:23px !important;" 
                      @click="showRoute(item)"
                      font-weight="bold"
                    >
                    {{ item.route_name.substring(0, 40) }} 
                    <br> {{ item.route_name.substring(40, 80) }} 
                    <br> {{ item.route_name.substring(80, 150) }}  
                    Total km: {{ item.total_km }} 
                    Greutate totala: {{ item.maximum_weight  }}
                    Lungime maxima: {{ item.maximum_l  }}
                    <br>
                    Clienti: {{ item.clients.substring(0,40) }}
                    <br> {{ item.clients.substring(40,80) }}
                    <br> {{ item.clients.substring(80,150) }}
                    {{ item.clients.substring(150,250) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>
    </div>

  </div>
</template>

<script>
import ShippingRoutes from '@/apis/ShippingRoutes.js';
import Admin from '@/apis/Admin.js';
import DirectionsRenderer from "@/pages/Maps/DirectionsRenderer";
import swal from 'sweetalert2';
import moment from 'moment'

export default {
  name: 'GoogleMap',
  components: {
    DirectionsRenderer,
  },
  data() {
    return {
      addresses: [],
      carryAccess: false,
      superAdminAccess: false,
      adminAccess: false,
      path: [
          {lat: '', lng: ''},
      ],
      colors: { 
        PROFMP: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
        PSPUST: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
        TPREV:  'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        PSANPA: 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png',
        COFRAJ: 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png',
        CROMAT: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
        INFRAS: 'https://maps.google.com/mapfiles/ms/icons/pink-dot.png',
        PREMIN: 'https://ik.imagekit.io/pcp6qvqvwl/output-onlinepngtools__1_.png?updatedAt=1637747622982',
        PRESDA: 'https://ik.imagekit.io/pcp6qvqvwl/output-onlinepngtools__3_.png?updatedAt=1637747740021',
        PROFMS: 'https://ik.imagekit.io/pcp6qvqvwl/output-onlinepngtools__4_.png?updatedAt=1637747848016',
        PROFTR: 'https://ik.imagekit.io/pcp6qvqvwl/output-onlinepngtools__5_.png?updatedAt=1637747889585',
        STRMET: 'https://ik.imagekit.io/pcp6qvqvwl/output-onlinepngtools__6_.png?updatedAt=1637747948248',
        TAMPL:  'https://ik.imagekit.io/pcp6qvqvwl/output-onlinepngtools__7_.png?updatedAt=1637748017218',
        VOPSUR: 'https://ik.imagekit.io/pcp6qvqvwl/output-onlinepngtools__10_.png?updatedAt=1637748131615'
      },
      data: {
        route_name: [],
        total_km: '',
        comment: '',
        uploaded_on: '',
        downloaded_on: '',
        maximum_weight: 0,
        maximum_l: [],
        addresses_ids: [],
        clients: [],
      },
      routes: [],
      model: '',
      infoWindow: {
        //position: {lat: 0, lng: 0},
        open: false,
        template: ''
      },
      polyLineColor: '#86caf7',
      location: null,
      destination: null,
      via: null,
      waypoints: [],
      startLocation: null,
      endLocation: null,
      maximumL: [],
      moment: moment
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAddresses();
      this.getShippingData();
      this.carryAccess = this.isCarry();
      this.superAdminAccess = this.isSuperAdmin();
      this.admin = this.isAdmin();
      //this.getRoute();
    });
  },
  
  watch: {
    'data.maximum_weigth': function(value) {
      if (value >= 22000) {
        new swal({
            title: 'Avertizare',
            text: "Ruta a depasit / ajuns la greutatea de 22000, sunteti sigur ca doriti sa creati aceasta ruta?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Da, sunt sigur!"
          })
      }
    }
  },

  methods: {
    setLocation(location) {
      this.location = location.name;
    },
    setDestination(destination) {
      this.destination = destination.name;
    },
    setVia(via) {
      this.via = via.name;
    },
    addMarker(index) {
      if (index === 0) { 
        this.startLocation = this.removeAccents(this.location);
        if (this.startLocation !== null) {
          this.data.route_name.includes(this.startLocation)
          ? window.showError('Adresa deja este adaugata!') 
          : this.data.route_name.push(this.startLocation);
        }
      } else if (index === 1) { 
        this.endLocation = this.removeAccents(this.destination); 
        if (this.startLocation !== null) {
          this.data.route_name.includes(this.endLocation) 
            ? window.showError('Adresa deja este adaugata!') 
            : this.data.route_name.push(this.endLocation);
        }
      } else if (index === 2) {

        if (this.startLocation !== null) {
        this.data.route_name.includes(this.via) 
          ? window.showError('Adresa deja este adaugata!') 
          : this.data.route_name.push(this.removeAccents(this.via));
        }

        this.waypoints.push({'location': this.via});
        this.via = '';

        let viaAutocompleteInput = document.getElementById('via');
        viaAutocompleteInput.value = ''
      }
    },
    getAddresses() {
      ShippingRoutes.getAddresses()
        .then((response) => {
          this.addresses = response.data.data
        })
    },

    getShippingData() {
      ShippingRoutes.getShippingData()
        .then((response) => {
          this.routes = response.data.data
        })
    },

    onAddressDelete(address) {
      if (this.isSuperAdmin || this.isAdmin) {
        const index = this.data.route_name.indexOf(address);
        if (index !== -1) {
          this.data.route_name.splice(index, 1);
          this.data.maximum_weight = 0;
          this.data.maximum_l = [];
        }
      }
    },

    createRoute() {
      if (this.isSuperAdmin || this.isAdmin) {
        if (this.data.maximum_weight > 0) {
          Admin.createRoute(this.data)
              .then(() => {
                window.showSuccess('Succes!', 'Ruta a fost creata cu succes!');
                this.getShippingData();
                this.startLocation = null;
                this.waypoints = [];
                this.endLocation = null;
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              });
        } else {
          window.showError('Eroare!', 'Nu ai selectat pinurile de pe ruta pentru calcul greutate');
        }
      }
    },

    openInfoWindowTemplate(address) {
      this.infoWindow.position = { lat: address.lat, lng: address.log }
      this.infoWindow.template = `<b>
                                  <b>
                                  Nume client - ${address.client_name}
                                  Oras - ${address.city}, 
                                  Adresa - ${address.address}, 
                                  Judet - ${address.county}
                                  <br>
                                  Nume persoana de contact - ${address.contact}
                                  <br>
                                  <br>Numar de telefon - ${address.phone_number}
                                  <br>
                                  <br>Descriere departament - ${address.department_description}<br>
                                  <br>Numar comanda - ${address.order_number}
                                  <br>
                                  <br>Lungime ${address.maximum_l}
                                  <br>
                                  <br>Greutate totala ${address.maximum_weight}
                                  <br>
                                  <br>Valoare marfa ${address.order_value} ${address.currency}
                                  <br>
                                  <br>Valoare transport ${address.shipping_value} ${address.currency}
                                  <br>Data sfarsit ${this.moment(address.final_date).format('DD/MM/YYYY')}
                                  </b>
                                  `
      this.infoWindow.open = true
    },

    showRoute(item) {
      const re = /\s*(?:,|$)\s*/;
      const routes = item.route_name.split(re)

      this.startLocation = '';
      this.endLocation = '';
      this.waypoints = [];

      this.startLocation = routes.shift();
      this.endLocation = routes.pop();
      routes.forEach((element) => {
        this.waypoints.push({'location': element});
      })
    },

    redirectToBids() {
      this.$router.push({ name: 'ShippingRoutes' })
    },

    addDetailsToRoute(address) {
      this.openInfoWindowTemplate(address);
        if (this.isSuperAdmin || this.isAdmin) {
          if (this.data.route_name.length >= 2) {
            new swal({
                title: 'Esti sigur ca vrei sa adaugi aceasta adresa ' + (address.city)  + '?' ,
                text: "Adaugand adresa urmatoarele urmatoarele informatii sunt trimise catre server: Id adresa, greutate, latime!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Da, sunt sigur!"
            }).then((result) => {
                if (result.value) {
                  this.data.maximum_weight += parseInt(address.maximum_weight, 10)
                  this.maximumL.push(address.maximum_l);
                  this.data.maximum_l.push(address.maximum_l);
                  this.data.addresses_ids.push(address.id);
                  this.data.clients.push(address.client_name);
                  window.showSuccess('Date adaugate cu succes');
                }
            });
          }
        }
    }
  }
};
</script>

<style src="./Google.scss" lang="scss" scoped/>
