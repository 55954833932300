<template>
  <v-container fluid>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Import csv</h1>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>Date import csv</p>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-title class="pa-6 pb-0">
              <p>Ultimul import efectuat: {{ timestamp }}</p>
            </v-card-title>
            <div class="text-center">
              <v-btn  
                  color="success"
                  align="center"
                  @click="syncProducts"
                  v-if="superAdminAccess === true"
                  dark>
                  Actualizeaza datele
              </v-btn>
              <br>
              <br>
               <v-btn  
                  color="success"
                  align="center"
                  @click="importProductsManually"
                  v-if="superAdminAccess === true"
                  dark>
                  Actualizeaza datele manual
              </v-btn>
              <br>
              <br>
              <form @submit.prevent id="importProductsForm">
                <input 
                  type="file" 
                  name="file" 
                  class="custom-file-input"
                  ref="importProducts"
                  @change="onFileChange"
                  v-if="superAdminAccess === true"
                  style="width:200px"
                >
              </form>
            </div>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">Id</th>
                      <th class="text-left">Sectia</th>
                      <th class="text-left">Descr. Sectie</th>
                      <th class="text-left">Nr. Comanda Vanzare</th>
                      <th class="text-left">Statut Comanda</th>
                      <th class="text-left">Cod. Client</th>
                      <th class="text-left">Nume Client</th>
                      <th class="text-left">Adresa</th>
                      <th class="text-left">Oras</th>
                      <th class="text-left">Judet</th>
                      <th class="text-left">Contact</th>
                      <th class="text-left">Telefon</th>
                      <th class="text-left">Tara</th>
                      <th class="text-left">Modalitate Exped.</th>
                      <th class="text-left">Descr tip cod exped</th>
                      <th class="text-left">Nume Agent</th>
                      <th class="text-left">L maxima/sectie</th>
                      <th class="text-left">Greutate totala</th>
                      <th class="text-left">Val.bal.-moneda com.</th>
                      <th class="text-left">Moneda</th>
                      <th class="text-left">Valoare transport</th>
                      <th class="text-left">Valoare comanda/secti</th>
                      <th class="text-left">Data sfarsit</th>
                      <th class="text-left">Termeni Plata</th>
                      <th class="text-left">Livrare??</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="import_product_data in import_products_data" :key="import_product_data.id">
                      <td class="pa-6">{{ import_product_data.id }}</td>
                      <td>{{ import_product_data.department }}</td>
                      <td>{{ import_product_data.department_description }}</td>
                      <td>{{ import_product_data.order_number }}</td>
                      <td>{{ import_product_data.order_status }}</td>
                      <td>{{ import_product_data.client_code }}</td>
                      <td>{{ import_product_data.client_name }}</td>
                      <td>{{ import_product_data.address }}</td>
                      <td>{{ import_product_data.city }}</td>
                      <td>{{ import_product_data.county}}</td>
                      <td>{{ import_product_data.contact }}</td>
                      <td>{{ import_product_data.phone_number }}</td>
                      <td>{{ import_product_data.country }}</td>
                      <td>{{ import_product_data.shipping_method }}</td>
                      <td>{{ import_product_data.shipping_code_description }}</td>
                      <td>{{ import_product_data.agent_name }}</td>
                      <td>{{ import_product_data.maximum_l  }}</td>
                      <td>{{ import_product_data.maximum_weight  }}</td>
                      <td>{{ import_product_data.bal_value  }}</td>
                      <td>{{ import_product_data.currency }}</td>
                      <td>{{ import_product_data.shipping_value  }}</td>
                      <td>{{ import_product_data.order_value  }}</td>  
                      <td>{{ import_product_data.final_date }}</td>  
                      <td>{{ import_product_data.payment_term }}</td>  
                      <td>{{ import_product_data.shipping }}</td>  
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Admin from '@/apis/Admin';
import Reports from '@/apis/Reports';
import moment from "moment";
export default {
  name: "Import",
  components: {},
  data() {
    return {
      superAdminAccess: false,
      import_products_data: [],
      data: {
        file: ''
      },
      overlay: false,
      timestamp: ''
    };
  },
  mounted() {
    this.superAdminAccess = this.isSuperAdmin();
    this.$nextTick(() => {
      this.getImportProductsData();
      if (this.isCarry() === true ) {
        this.$router.push({ name: 'ShippingRoutes' });
      }
      this.getLatestTimestamp();
    })
  },
  methods: {
    getImportProductsData() {
      Admin.importProductsData()
        .then((response) => {
          this.import_products_data = response.data.data
        })
    },

    syncProducts() {
      this.overlay = true;
      Admin.syncProducts()
        .then(() => {
          this.overlay = false;
          window.showSuccess('Succes', 'Datele au fost actualizate cu succes!');
          this.getImportProductsData();
        })
    },

    importProductsManually() {
      let form = document.getElementById("importProductsForm")
      let data = new FormData(form)
      data.append('file', this.data.file);
      if (this.data.file === '') {
        window.showError('Eroare!', 'Trebuie sa alegi mai intai un fisier pentru a putea realiza aceasta actiune!');
        return;
      }
      this.overlay = true;
      Admin.importProductsManually(data)
        .then(() => {
          this.overlay = false;
          window.showSuccess('Succes', 'Datele au fost actualizate cu succes!');
          this.getImportProductsData();
          this.$refs.importProducts.value = '';
        })
    },

    onFileChange(event) {
      this.data.file = event.target.files[0]
      if (this.data.file.type !== "text/csv" && this.data.file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        window.showError('Eroare!', 'Fisierul nu este unul valid. Sunt acceptate fisiere csv sau xlsx');
        this.$refs.importProducts.value = '';
      }
    },

    getLatestTimestamp() {
      Reports.getLatestTimestamp()
        .then((response) => {
          this.timestamp =  moment(response.data.data.created_at).format("DD-MM-YYYY H:m:s");
        });
    }
  }
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
