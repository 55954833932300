import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps';
import VeeValidate, { Validator } from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';
import ro from 'vee-validate/dist/locale/ro';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue'

import "vue-toastification/dist/index.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDI5gTvPv8l-eF-vInsgWrZ82pENdBxUGc",
    libraries: 'places',
  },
  installComponents: true
});

Vue.mixin({
  methods: {
    isSuperAdmin() {
      const isSuperAdmin = localStorage.getItem('IS_SUPER_ADMIN');
      return isSuperAdmin === "true" ? true : false;
    },
    isAdmin() {
      const isAdmin = localStorage.getItem('ADMIN');
      return isAdmin === "true" ? true : false;
    },
    isCarry() {
      const isCarry = localStorage.getItem('TRANSPORTATOR');
      return isCarry === "true" ? true : false;
    },
    removeAccents(strAccents){
      strAccents = strAccents.split('');
      let strAccentsOut = new Array();
      let strAccentsLen = strAccents.length;
      var accents = 'ăĂșȘțȚ';
      var accentsOut = ['a', 'A', 's', 'S', 't', 'T'];
      for (var y = 0; y < strAccentsLen; y++) {
          if (accents.indexOf(strAccents[y]) != -1) {
              strAccentsOut[y] = accentsOut[accents.indexOf(strAccents[y])];
          }
          else
              strAccentsOut[y] = strAccents[y];
      }
      strAccentsOut = strAccentsOut.join('');
      return strAccentsOut;
    }
  }
});

Vue.use(VeeValidate);

Vue.use(VueRouter);

Validator.localize('ro', ro);

Vue.use(VueSweetalert2);

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

require('./alert.js');

new Vue({
  vuetify,
  router,
  render: h => h(App), store
}).$mount('#app')
