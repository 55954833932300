<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="success"
          dark
          v-bind="attrs"
          v-on="on"
          v-if="superAdminAccess === true"
        >
          Adauga utilizator
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Adauga utilizator
        </v-card-title>
        <v-form>
            <v-col>
                <v-text-field
                    label="Nume"
                    name="first_name"
                    v-validate="'required|alpha'"
                    data-vv-as="nume"
                    v-model="data.first_name"
                    required
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('first_name') }}
                        </div>
                    </div>
                </div>

                <v-text-field
                    label="Prenume"
                    name="last_name"
                    v-validate="'required|alpha'"
                    data-vv-as="prenume"
                    v-model="data.last_name"
                    required
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('last_name') }}
                        </div>
                    </div>
                </div>

                <v-text-field
                    label="Numele companiei"
                    name="company_name"
                    v-validate="'required|alpha'"
                    data-vv-as="numele companiei"
                    v-model="data.company_name"
                    required
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('company_name') }}
                        </div>
                    </div>
                </div>

                <v-text-field
                    label="Numar de telefon"
                    name="phone_number"
                    v-validate="'required|numeric'"
                    v-model="data.phone_number"
                    data-vv-as="numar de telefon"
                    required
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('phone_number') }}
                        </div>
                    </div>
                </div>

                <v-text-field
                    label="Email"
                    name="email"
                    v-validate="'required|email'"
                    v-model="data.email"
                    data-vv-as="email"
                    required
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('email') }}
                        </div>
                    </div>
                </div>

                <v-select
                  :items="userTypes"
                  v-model="data.roles"
                  @onChange="data.roles"
                ></v-select>

                <v-text-field
                    label="Parola"
                    name="password"
                    type="password"
                    v-validate="'required|alpha_num|min:8'"
                    v-model="data.password"
                    data-vv-as="parola"
                    required
                ></v-text-field>
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                            {{ errors.first('password') }}
                        </div>
                    </div>
                </div>
            </v-col>
        </v-form>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="createAdmin"
          >
            Adauga utilizator
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Admin from '@/apis/Admin.js';
  export default {
    props: {
        superAdminAccess: Boolean
    },
    data () {
      return {
        dialog: false,
        userTypes: ['ADMIN', 'TRANSPORTATOR'],
        data: {
            first_name: '',
            last_name: '',
            phone_number: '',
            company_name: '',
            email: '',
            password: '',
            roles: 'ADMIN'
        }
      }
    },
    methods: {
        createAdmin() {
            Admin.createAdmin(this.data)
                .then(() => {
                    this.dialog = false;
                    window.showSuccess('Succes!', 'Utilizatorul a fost creat cu succes!')
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                });
        }
    }
  }
</script>