<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/logo.svg" contain></v-img>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-tabs grow>
                  <v-tab :href="`#tab-login`">
                    Autentificare 
                  </v-tab>
                  <v-tab :href="`#tab-forgottenPassword`">
                    Recuperare Parola
                  </v-tab>
                  <v-tab-item :value="'tab-login'" >
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col>
                            <p class="login-slogan display-2 text-center font-weight-medium my-10">Bine ai venit!</p>
                          </v-col>
                          <v-form>
                            <v-col>
                              <v-text-field
                                  name="email"
                                  v-model="data.email"
                                  v-validate="'required|email'"
                                  label="Adresa de email"
                                  data-vv-as="email"
                                  required
                              ></v-text-field>
                              <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        {{ errors.first('email') }}
                                    </div>
                                </div>
                              </div>
                              <v-text-field
                                  name="password"
                                  v-model="data.password"
                                  type="password"
                                  label="Parola"
                                  data-vv-as="parola"
                                  v-validate="'required|min:8'"
                                  required
                              ></v-text-field>
                              <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        {{ errors.first('password') }}
                                    </div>
                                </div>
                              </div>
                            </v-col>
                            <v-col class="d-flex justify-space-between">
                              <v-btn
                                  class="text-capitalize"
                                  large
                                  color="primary"
                                  @click="login"
                              >
                                Autentificare</v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>

                  <v-tab-item :value="'tab-forgottenPassword'" >
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">

                          <v-col>
                            <p class="login-slogan text-center font-weight-medium sm-4">
                              Introduceti mai jos email-ul asociat contului tau
                            </p>
                          </v-col>

                          <v-form>
                            <v-col>
                              <v-text-field
                                  label="Email"
                                  name="email"
                                  v-validate="'required|email'"
                                  v-model="forgottenPassword.email"
                                  required
                              ></v-text-field>
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        {{ errors.first('email') }}
                                    </div>
                                </div>
                            </div>
                            </v-col>
                            <v-col class="d-flex justify-space-between">
                              <v-btn
                                  large
                                  class="text-capitalize-forgot-password"
                                  block
                                  color="primary"
                                  @click="sendForgotPasswordEmail"
                              >
                                Trimite email-ul de resetare</v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© {{ date.getFullYear() }} <a href="/" class="text-decoration-none"> Proinvest Group SRL • Str. Gradinitei nr. 1, RO - 705200 Pascani (Iasi)</a></div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import User from "@/apis/User.js";
  export default {
    name: 'Login',
    data() {
      return {
        data: {
          email: '',
          password: ''
        },
        forgottenPassword: {
          email: ''
        },
        date: new Date()
      }
    },
    methods: {
      login() {
        User.login(this.data)
          .then((response) => {
            this.$root.$emit("login", true);
            localStorage.setItem("auth", true);
            localStorage.setItem("token", response.data.data.token);
            
            response.data.data.user.roles === 'SUPER_ADMIN' 
              ? localStorage.setItem("IS_SUPER_ADMIN", true) 
              : localStorage.setItem("IS_SUPER_ADMIN", false);

            response.data.data.user.roles === 'TRANSPORTATOR' 
              ? localStorage.setItem("TRANSPORTATOR", true) 
              : localStorage.setItem("TRANSPORTATOR", false);
            
             response.data.data.user.roles === 'ADMIN' 
              ? localStorage.setItem("ADMIN", true) 
              : localStorage.setItem("ADMIN", false);

            if (response.data.data.user.roles === 'TRANSPORTATOR') {
              this.$router.push({ name: "ShippingRoutes" });
            } else {
              this.$router.push({ name: "Dashboard" });
            }
          })
      },
      sendForgotPasswordEmail() {
        User.sendForgotPasswordEmail(this.forgottenPassword)
          .then(() => {
            window.showSuccess('Succes!', 'Daca email-ul adaugat exista in baza de date, o sa primesti un mesaj cat mai repede posibil cu detaliile necesare pentru resetarea parolei')
          })
          .catch(() => {
            window.showSuccess('Succes!', 'Daca email-ul adaugat exista in baza de date, o sa primesti un mesaj cat mai repede posibil cu detaliile necesare pentru resetarea parolei')
          })
      }
    },
    created() {
      if (window.localStorage.getItem('auth') === 'true') {
        this.$router.push('/dashboard');
      }
    }
  }

</script>

<style src="./Login.scss" lang="scss"/>
