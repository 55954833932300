<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          style="font-size: 28px" color="success"
          dark
          v-bind="attrs"
          v-on="on">mdi-eye</v-icon>
    </template>
    <v-card
      >
        <v-card-title class="text-h5 grey lighten-2">
          Detalii ruta
        </v-card-title>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Ruta: {{ this.route.route_name }}</v-list-item-title>
            <v-list-item-title>Total km: {{ this.route.total_km }}</v-list-item-title>
            <v-list-item-title>Lungime: {{ this.route.maximum_l }}</v-list-item-title>
            <v-list-item-title>Greutate: {{ this.route.maximum_weight }}</v-list-item-title>
            <v-list-item-title>Clienti: 
                {{ this.route.clients.substring(0,40) }} 
                <br> {{ this.route.clients.substring(40,80) }} 
                <br> {{ this.route.clients.substring(80,120) }} 
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    route: Object
  },
  data () {
    return {
      dialog: false,
    }
  },
}
</script>